<template>
  <div @mouseleave="mouseout" @mousedown="defaultClicked">
    <ContextMenu id="ctx" ref="ctx" @ctx-cancel="clearCtx" @ctx-close="clearCtx">
      <li v-for="menu in menuOptionsOrDefault" :key="menu.event" class="ctx-item" @click="menuClicked(menu)">
        {{ menu.text }}
      </li>
    </ContextMenu>
  </div>
</template>

<script>
import ContextMenu from "@/components/contextMenu/context-menu.vue";

export default {
  components: { ContextMenu },
  props: {
    select: { type: Boolean, default: false },
    edit: { type: Boolean, default: true }, // Note Edit is true by default
    delay: { type: Number, default: 400 },
    menuOptions: { type: Array, default: null }
  },
  data() {
    return {
      // when showing context menu on click and double click functionality also, they get in the way of each other
      // so delay showing context and cancel if double click done
      showingContextTimer: null,
      // use to simulate double click , detect click just after shown
      timeShown: null
    };
  },
  computed: {
    // Default options, Select if prop select passed in, with Edit
    // Otherwise, pass in Array in prop menuOptions [{ text: "text", event: "edit" }] with event name passed in menu-clicked event
    menuOptionsOrDefault() {
      if (this.menuOptions) return this.menuOptions;
      const menuOptions = [];
      if (this.select) menuOptions.push({ text: "Select", defaultEvent: "select" });
      const editViewEvent = {
        defaultEvent: this.select ? "" : "edit",
        event: this.select ? "edit" : ""
      };
      // Edit or View
      if (this.edit) menuOptions.push({ ...editViewEvent, text: "Edit" });
      else menuOptions.push({ ...editViewEvent, text: "View" }); // View same event as Edit, but resulting screen should be readonly

      return menuOptions;
    }
  },
  methods: {
    open(mousedownEvent) {
      // offeset menu to account for padding
      const pos = {
        pageY: mousedownEvent.pageY - 10,
        pageX: mousedownEvent.pageX - 10
      };

      if (!this.showingContextTimer) {
        this.showingContextTimer = setTimeout(() => {
          if (this.$refs.ctx) this.$refs.ctx.open(pos);
          this.timeShown = new Date().getTime();
        }, this.delay);
      }
    },
    close() {
      this.clearCtx();
    },
    clearCtx() {
      clearTimeout(this.showingContextTimer);
      this.showingContextTimer = null;
    },
    mouseout() {
      this.$refs.ctx.ctxVisible = false;
      this.clearCtx();
    },
    menuClicked(menu) {
      // emit the default event, or the menu.event in the "menu-clicked" event
      if (menu.defaultEvent) this.$emit(menu.defaultEvent);
      else this.$emit("menu-clicked", menu.event);
    },
    defaultClicked() {
      // simulate double click, by detecting click just after its appeared
      if (new Date().getTime() - this.timeShown < 300) {
        // fire top option
        this.menuClicked(this.menuOptionsOrDefault[0]);
      }
    }
  }
};
</script>
<style>
/* Padding border used to detect the mouse out and hide the menu */
.ctx {
  margin: 20px !important;
}
.ctx-menu {
  position: relative !important;
  float: none !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}
.ctx-menu-container {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>
