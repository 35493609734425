<template>
  <div class="has-text-centered">
    <p v-if="isSmall" class="mt-1">{{ noDataMsg }}</p>
    <div v-else class="mt-4">
      <b-icon icon="emoticon-sad" size="is-large" />
      <p>No data found that matches the search criteria</p>
    </div>
  </div>
</template>

<script>
export default {
  props: { isSmall: { type: Boolean }, noDataMsg: { type: String, default: "No data found" } }
};
</script>
