// For use in parent  with ContextMenu component
// Parent Container needs to call @mousedown="captureMouse"
// Call closeContextMenu on external navigation such as Double Click
export default {
  data() {
    return {
      mousedownEvent: null
    };
  },
  methods: {
    // Capture mouse down postion
    captureMouse(e) {
      this.mousedownEvent = e;
    },
    showContextMenu() {
      this.$refs.ctx?.open(this.mousedownEvent);
    },
    closeContextMenu() {
      this.$refs.ctx.close();
    },
    contextDefault() {
      this.$emit("contextDefaultClicked");
      this.closeContextMenu();
    }
  }
};
